//https://www.npmjs.com/package/geoip-lite
//https://stackoverflow.com/questions/391979/how-to-get-clients-ip-address-using-javascript

// var geoipLite = require('geoip-lite')
// var axios = require('axios')

module.exports = {
  // async getMyGeoIp() {
  //   // var ip = await getMyIp();
  //   var ip = '95.237.81.29'
  //   var geo = geoipLite.lookup(ip);
  //   console.log('geo: ', geo);
  //   return geo
  // }
  // getMyIp() {
  //   return new Promise( async (resolve, reject) => {
  //     axios.get('http://gd.geobytes.com/GetCityDetails')
  //     .then((data) => {
  //       console.log('data: ', JSON.stringify(data.data, null, 2));
  //       resolve(data.data.geobytesipaddress)
  //     })
  //     .catch((err) => {
  //       console.log('err: ', err)
  //       reject(err)
  //     })
  //   })
  // }  
}