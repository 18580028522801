import Api from '@/services/Api'
import axios from 'axios'
import fetch from 'node-fetch'
import config from '@/config'

export default {
  getShopMeta(id) {
    return Api().get('/model/shop/'+id+'?include=true')
  },
  saveMetaShop(obj, id) {
    console.log('Patch Meta')
    return Api().patch('/model/shop/'+id+'/meta', obj)
    // fetch('https://myescapi.stayted.com/model/shop/'+id+'/meta', {
    //     method: 'patch',
    //     body:    JSON.stringify(obj),
    //     headers: { 'Content-Type': 'application/json' },
    // })
    // .then(res => res)
    // .catch(err => err)
  },
  saveInfoShop(obj, id) {
    return Api().patch('/model/shop/'+id+'?include=true', obj)
  },
  getAllShops() {
    return Api().get('/model/shop')
  },
  // chiamato da : @/views/DashboardCompanyMyShop.vue
  // quando      : si atterra su quella route con un token settato da vo-panel
  // cosa fa     : si fa dare un token valido per l'editing del negozio shop_id
  convertToken( token, id, type ) {
    return axios.create({
        baseURL: config.backend_url,
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*'
        }
    }).get(`/model/user/convert/?jwt=${ token }&id=${ id }&type=${ type }`);
  }
}
