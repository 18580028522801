export const user_meta = {
  namespaced: true,
  state: {
    user_meta: null,
  },
  mutations: {
    setUserMeta (state, user_meta) {
      state.user_meta = user_meta
    }
  },
  actions: {
    setUserMeta ({ commit }, user_meta) {
      commit('setUserMeta', user_meta)
    }
  }
}