<template>
  <div class="iframe">
    <h4 class="back_home" @click="backHome()"><i class="im im-icon-Left-4"></i> Torna alla pagina negozio</h4>
    <div class="fs-container content">
      <h4>Menù</h4>
      <!-- <p><i>{{ application.description }}</i></p> -->
      <iframe class="col-xs-12" :src="'https://myfood.okkam.it/myfood-web/it/menus/volid-'+volid" title="Menu"></iframe>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'tmpiframe',
  props: ['volid'],
  data() {
    return {
    }
  },
  methods: {
    backHome() {
      this.$eventHub.$emit('tmp_iframe_view_event', true)
    }
  }
}
// this.$eventHub.$emit('highlight', index)
</script>

<style scoped>
  .back_home {
    color: #333;
    cursor: pointer;
    transition: .3s;
  }
  .back_home:hover {
    color: #f91942;
    transition: .3s;
    text-decoration: underline;
  }
  .content {
    margin-top: 25px;
  }
  .content iframe {
    border: none;
    height: 100vh;
  }
</style>