<template>
  <!-- Banner
  ================================================== -->
  <div>
    <HeaderCustom/>
    <div class="main-search-container centered" :data-background-image="{ backgroundImage: 'url(' + require('@/assets/images/main-search-background-01.jpg') + ')'}" :style="{ backgroundImage: 'url(' + require('@/assets/images/main-search-background-01.jpg') + ')'}">
      <div class="main-search-inner">

        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h2>
                Vetrine OnLine: 
                <!-- Typed words can be configured in script settings at the bottom of this HTML file -->
                <span class="typed-words"></span>
              </h2>
              <h4>Cerca i migliori negozi vicino a casa tua e ordina da casa</h4>
              <!-- <div class="main-search-input">

                <div class="main-search-input-item">
                  <input type="text" placeholder="What are you looking for?" value=""/>
                </div>

                <div class="main-search-input-item location">
                  <div id="autocomplete-container">
                    <input id="autocomplete-input" type="text" placeholder="Location">
                  </div>
                  <a href="#"><i class="fa fa-map-marker"></i></a>
                </div>

                <div class="main-search-input-item">
                  <select data-placeholder="All Categories" class="chosen-select" >
                    <option>All Categories</option>	
                    <option>Shops</option>
                    <option>Hotels</option>
                    <option>Restaurants</option>
                    <option>Fitness</option>
                    <option>Events</option>
                  </select>
                </div>

                <button class="button col-md-2 text-center" onclick="window.location.href='listings-half-screen-map-list.html'">Search</button>

              </div> -->
              <div class="container" style="margin-top: 25px;">
                <div class="row">
                  <div class="col text-center">
                    <a v-if="!this.$store.state.user.isUserLoggedIn" href="#sign-in-dialog" class="button register sign-in popup-with-zoom-anim custom_modal_log">Accedi</a>
                    <a href="/shops-list" class="button">Cerca Negozi</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Features Categories -->
          <!-- <div class="row">
            <div class="col-md-12">
              <h5 class="highlighted-categories-headline">Or browse featured categories:</h5>
                
              <div class="highlighted-categories"> -->
                <!-- Box -->
                <!-- <a href="listings-list-with-sidebar.html" class="highlighted-category">
                    <i class="im im-icon-Home"></i>
                    <h4>Apartments</h4>
                </a>	 -->

                <!-- Box -->
                <!-- <a href="listings-list-full-width.html" class="highlighted-category">
                    <i class="im im-icon-Hamburger"></i>
                    <h4>Eat &amp; Drink</h4>
                </a>	 -->

                <!-- Box -->
                <!-- <a href="listings-half-screen-map-list.html" class="highlighted-category">
                    <i class="im im-icon-Electric-Guitar"></i>
                    <h4>Events</h4>
                </a>	 -->

                <!-- Box -->
                <!-- <a href="listings-half-screen-map-list.html" class="highlighted-category">
                    <i class="im im-icon-Dumbbell"></i>
                    <h4>Fitness</h4>
                </a>		
              </div>
              
            </div>
          </div> -->
          <!-- Featured Categories - End -->

        </div>

      </div>
    </div>
    <section class="fullwidth padding-top-75 padding-bottom-70" data-background-color="#f9f9f9" v-if="!this.$store.state.user.isUserLoggedIn">
      <div class="container">

        <div class="row icons-container">

          <!-- Stage -->
          <div class="col-md-6">
            <div class="icon-box-2 with-line">
              <i class="im im-icon-Shop-2"></i>
              <h3>Sei un Esercente?</h3>
              <p>Registrati come Esercente su Vetrine OnLine, crea il tuo negozio e resta in contatto con i tuoi cliente</p>
              <a href="#sign-in-dialog" class="button register sign-in popup-with-zoom-anim custom_modal_reg" style="margin-top:25px;">Registrati Qui!</a>
            </div>
          </div>

          <!-- Stage -->
          <div class="col-md-6">
            <div class="icon-box-2">
              <i class="im im-icon-Checked-User"></i>
              <h3>Sei un Utente?</h3>
              <p>Registrati come Utente su Vetrine OnLine, cerca i tuoi negozi di fiducia e ordina da casa</p>
              <a href="#sign-in-dialog" class="button register sign-in popup-with-zoom-anim custom_modal_ut" style="margin-top:25px;">Registrati Qui!</a>
            </div>
          </div>
        </div>

      </div>
      </section>
    <div class="col-xs-12 text-center" style="background-color: #fbfbfb; border-bottom: 1px solid #eaeaea; padding: 40px;">
      <h4>Assistenza</h4>
      <h4><a href="mailto:assistenza@vetrineonline.com">assistenza@vetrineonline.com</a></h4>
    </div>
    <FooterCustom/>
  </div>
</template>
<script>
// @ is an alias to /src
import HeaderCustom from '@/components/Header'
import HeaderNew from '@/components/HeaderNew'
import FooterCustom from '@/components/Footer'

//import Typed from '@/scripts/typed.js'
import Typed from 'typed.js'

// import '@/scripts/jquery-3.4.1.min.js'
// import '@/scripts/jquery-migrate-3.1.0.min.js'
// import '@/scripts/mmenu.min.js'
// import '@/scripts/chosen.min.js'
// import '@/scripts/slick.min.js'
// import '@/scripts/rangeslider.min.js'
// import '@/scripts/magnific-popup.min.js'
// import '@/scripts/waypoints.min.js'
// import '@/scripts/counterup.min.js'
// import '@/scripts/jquery-ui.min.js'
// import '@/scripts/tooltips.min.js'
// import '@/scripts/custom.js'
export default {
  name: 'Home',
  data () {
    return {
    }
  },
  components: {
    HeaderCustom,
    FooterCustom
  },
  mounted () {
    var typed = new Typed('.typed-words', {
      strings: ["Panificio"," Macelleria", "Ortofrutta"],
      typeSpeed: 80,
      backSpeed: 80,
      backDelay: 4000,
      startDelay: 1000,
      loop: true,
      showCursor: true
    });
    this.$nextTick()
      .then(function () {
        let custom = document.createElement('script')
        custom.setAttribute('type', 'text/javascript')
        custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
        // custom.async = true;
        document.body.appendChild(custom)
      })
  },
  created () {
  }
}
</script>
<style scoped>
  .register:hover {
    color: white !important;
    transition: 0.3s;
  }
</style>
