import Api from '@/services/Api'
import MultipartApi from '@/services/MultipartApi'
import axios from 'axios'
import store from '@/store'
import config from '@/config'

export default {
  //createShop da SISTEMARE,creato io
  // -------
  createShop(credentials) {
    console.log(credentials)
    return Api().post('/model/user/addShop',credentials)
  },
  register (credentials) {
    console.log(credentials)
    //return MultipartApi().post('/user/register', credentials)
    return Api().post('/model/user/register', credentials)
  },
  login (credentials) {
    return Api().post('/model/user/authenticate?include=true', credentials)
  },
  updateCredentials(companyId, data) {
    return Api().put('/model/user/update-credentials/' + companyId, data)
  },
  updatePassword(user_id, password) {
    return Api().post('/model/user/savepsw', password)
  },
  confirmTokenAuth () {
    return Api().get('/model/user/jwt-validation')
  },
  sendEmailResetPassword (email) {
    return Api().post('/model/user/changepsw', email)
  },
  confirmTokenAuthResetPassword (token) {
    return axios.create({
      baseURL: config.backend_url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).get('/model/user/jwt-validation')
  },
  resetPassword(token, password) {
    return axios.create({
      baseURL: config.backend_url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).post('/model/user/savepsw', password)
  },
  confirmRegistration(token, playload) {
    return axios.create({
      baseURL: config.backend_url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).patch('/model/user/confirm', playload)
  },
}
