export const shop_address = {
  namespaced: true,
  state: {
    address: null,
  },
  mutations: {
    setAddress (state, address) {
      state.address = address
    },
    setShopAddress (state, shop_address) {
       for (let i = 0; i<state.address.length; i++) {
         if (state.address[i].label === 'negozio') {
           state.address[i] = shop_address
           break
         }
       }
      //state.address.filter((el) => el.label === 'negozio')[0] = shop_address
    },
    setLegalAddress (state, legal_address) {
      for (let i = 0; i<state.address.length; i++) {
        if (state.address[i].label === 'ragione_sociale') {
          state.address[i] = legal_address
          break
        }
      }
    }
  },
  actions: {
    setAddress ({ commit }, address) {
      commit('setAddress', address)
    },
    setShopAddress ({ commit }, shop_address) {
      commit('setShopAddress', shop_address)
    },
    setLegalAddress ({ commit }, legal_address) {
      commit('setLegalAddress', legal_address)
    }
  }
}