<template>
  <div id="app">
    <div id="wrapper">
      <!-- <div style="position:fixed; top: 0; left: 0; right: 0; bottom: 0; width; 100%; height: 100%; z-index: 9999999;"  v-if="get_calling">
        <div class="call-animation">
          <div class="col-xs-12" style="height: 40px; background: #333;">
            <p style="color:white;">{{ getInfoCall.user_name }}</p>
          </div>
          <div class="col-xs-12">
            <h3>Chiamata in arrivo da {{ getInfoCall.user_name }}</h3>
          </div>
          <div style="margin-top: 200px;" class="col-xs-12 text-center">
            <i class="im im-icon-Phone-2" style="font-size:40px; color: red; cursor: pointer;" @click="closeCall()"></i>
            <i class="im im-icon-Telephone" style="font-size:40px; color: green; cursor: pointer;" @click="answerCall()"></i>
          </div>
        </div>
      </div> -->
      <!-- <div id="custom_overlay" v-show="video_call">
        <div class="meet_container col-xs-12 col-md-12 text-center">
          <div class="header_container col-xs-12 col-md-12">
            <div class="header_meet">
              <div class="col-xs-6 text-left">
                <h3>Chiamata con prova</h3>
              </div>
              <div class="col-xs-6 text-right">
                <div class="meet_close" @click="closeMeet()">
                  <i class="fa fa-close"></i>
                </div>
              </div>
            </div>
          </div>
          <div id="meet"></div>
        </div>
      </div> -->
      <router-view>
      </router-view>
      <incoming-call id="inccall" :name="getInfoCall.user_name" :type="'shop'" v-if="get_calling"/>
      <video-call-shop style="z-index:99999;" v-if="video_call" :shop_name="$store.state.shop.shop.label" :first_name="info_call.user_name" :url="info_call.room_name"></video-call-shop>
      <!-- Back To Top Button -->
      <div id="backtotop"><a href="#"></a></div>
    </div>
  </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
import config from '@/config'
import IncomingCall from './components/IncomingCall'
import VideoCallShop from '@/views/VideoCallShop'
import JitsiMeetExternalAPI from '@/utils/jitsi'
import Vue from 'vue'
Vue.component( 'video-call-shop', VideoCallShop );
Vue.component( 'incoming-call', IncomingCall );
export default {
  name: 'app',
  components: {
    // IncomingCall,
    // VideoCallShop: () => import('./views/VideoCallShop')
  },
  data() {
    return {
      // tokenValid: false,
      connection: null,
      connection_call: null,
      config: null,
      calling: false,
      info_call: {
        room_name: '',
        user_name: ''
      },
      video_call: false,
      domain: '',
      options: {},
      api: null,
      audio: null,
      varCallAcceppted: false
    }
  },
  created () {
    this.$eventHub.$on('close_call_shop', this.closeMeet)
    this.$eventHub.$on('call_accepted_shop', this.callAcceppted)
    this.$eventHub.$on('call_closed_shop', this.callClosed)
    this.$eventHub.$on('answer_call', this.answerCall)
    this.$eventHub.$on('dont_answer_shop', this.closeCall)
    this.config = config
    if (this.isUserLoggedIn && this.isUserShop) 
      this.connectionWS()
    else
      console.log('NO WS')
  },
  beforeDestroy () {
    this.$eventHub.$off('close_call_shop')
    this.$eventHub.$off('call_accepted_shop')
    this.$eventHub.$off('call_closed_shop')
    this.$eventHub.$off('answer_call')
    this.$eventHub.$off('dont_answer_shop')
  },
  computed: {
    isUserLoggedIn: function() {
      return this.$store.state.user.isUserLoggedIn
    },
    isUserShop: function() {
      if (this.$store.state.user.user.role_id === 5) {
        return this.$store.state.shop.shop ? this.$store.state.shop.shop.id ? true : false : false 
      }
      return false
    },
    getInfoCall: function() {
      return this.info_call
    },
    get_calling: function() {
      return this.calling
    }
  },
  components: {
  },
  async mounted () {
    if (this.$store.state.user) {
      if (this.$store.state.user.isUserLoggedIn) { // se un utente risulta essere loggato controllo la validità del suo token
        try {
          const res = await AuthenticationService.confirmTokenAuth()
          console.log('Token Valid? ', res.data)
        } catch (error) {
          console.log('logout .. ')
          console.log('Sessione Scaduta!')
          var role_id = this.$store.state.user.user.role_id
          this.$store.dispatch('user/setToken', null)
          this.$store.dispatch('user/setUser', null)
          this.$store.dispatch('shop/setShop', null)
          this.$store.dispatch('shop_meta/setShopMeta', null)
          this.$store.dispatch('shop_address/setAddress', null)
          this.$store.dispatch('user_address/setAddress', null)
          this.$store.dispatch('user_meta/setUserMeta', null)
          this.$store.dispatch('shop_images/setLogo', null)
          this.$store.dispatch('shop_images/setGallery', null)
          this.$store.dispatch('my_shops/setMyShops', [])
          if(role_id === 5) {
            this.$store.dispatch('presence_ws/setStatus', '')
          }
          this.$router.push({
            name: 'home'
          })
          console.log('err ', error)
        }
      }
    }
    this.$watch(
      () => {
          return this.$store.state.user.isUserLoggedIn
      },
      (val) => {
        if(val) {
          // console.log('LOGGATO. START: ', val)
          // // this.connectionWS()
        } else {
          console.log('chiudo la connessione')
          if(this.connection)
            this.connection.close()
        }
      }
    )
      // this.tokenValid=true
  },
  beforeDestroy() {
    if(this.connection)
      this.connection.close()
  },
  methods: {
    connectionWS() {
      // console.log('nuova connessione')
      var shop_id = this.$store.state.shop.shop.id
      var url = config.backend_url.replace('http', 'ws') + 'ws/presence?shop_id=' + shop_id
      var vm = this
      try {
        this.connection = new WebSocket(url)
        this.$store.dispatch('presence_ws/setConnection', this.connection)
      } catch(error) {
        // console.log('errore durante la connessione, ristabilisco la connessione...')
        setTimeout(() => {
          vm.connectionWS()
          return
        }, 3000)
      }
      var ws = this.connection
      ws.onerror = function() {
        // console.log('Connection Error');
      }

      ws.onclose = function() {
        console.log('echo-protocol Client Closed');
        var loggedIn = vm.$store.state.user.isUserLoggedIn
        setTimeout(() => {
          console.log('riconnessione...')
          if(loggedIn)
            vm.connectionWS()
          // else
            // console.log('Sei sloggato')
        }, 5000)
        return
      }

      ws.onopen = function(event) {
        // console.log('websocket is connected ...');
        // console.log(event)
      }

      ws.onmessage = function(e) {
        // console.log("Received: '" + e.data + "'")
        // console.log('AAAA',JSON.parse(e.data).type)
        function confirmPresence() {
          // console.log(ws);
          // if (ws.connected) {
            var status = vm.$store.state.presence_ws.status
            // console.log("Mando un messaggio di risposta:", status)
            var payload = { status: status }
            ws.send( JSON.stringify(payload) );
          // }
          return
        }
        confirmPresence()
        if(JSON.parse(e.data).type === "incoming_call") {
          vm.info_call.room_name = JSON.parse(e.data).conf.room_name
          vm.info_call.user_name = JSON.parse(e.data).conf.user_name
          vm.calling = true
          vm.videoCallWS()
          setTimeout(() => {
            if(!vm.varCallAcceppted) {
              vm.info_call.room_name = ''
              vm.info_call.user_name = ''
            }
            vm.calling = false
          }, 30000)
        }
      }
    },
    videoCallWS() {
      var shop_id = this.$store.state.shop.shop.id
      var url = config.backend_url.replace('http', 'ws') + 'ws/call/?shop_id=' + shop_id
      var vm = this
      this.audio = new Audio(require('./assets/ringing_phone.mp3'));
      this.audio.play();
      this.audio.loop = true
      try {
        this.connection_call = new WebSocket(url)
      } catch(error) {
        console.log('errore durante la connessione call ws, ristabilisco la connessione...')
        setTimeout(() => {
          vm.videoCallWS()
          vm.calling = false
          vm.audio.pause();
          vm.audio.currentTime = 0;  
          vm.audio = null
          return
        }, 3000)
      }
      var ws = this.connection_call
      ws.onerror = function() {
        // console.log('Connection Error');
        vm.calling = false
        vm.audio.pause();
        vm.audio.currentTime = 0;
        vm.audio = null
        vm.varCallAcceppted = false
      }
      ws.onclose = function() {
        console.log('echo-protocol Client Closed CALL');
        vm.video_call = false
        vm.connection_call = null
        vm.$store.dispatch('presence_ws/setStatus', 'free')
        vm.calling = false
        vm.varCallAcceppted = false
        if(vm.audio) {
          vm.audio.pause();
          vm.audio.currentTime = 0;
          vm.audio = null
        }
        ws.close()
        return
      }
      ws.onopen = function(event) {
        // console.log('websocket CALL is connected ...');
        // console.log(event)
      }
      ws.onmessage = function(e) {
        // console.log("Received CALL: '" + e.data + "'")
        if(JSON.parse(e.data).type === 'call_terminated') {
          // console.log('Il cliente ha buttato giù')
          vm.connection_call = null
          vm.calling = false
          ws.close()
        }
      }
    },
    closeCall() {
      // console.log('sto mandando call rejected')
      this.connection_call.send(JSON.stringify({ type: 'call_rejected' }))
      this.connection_call.close()
      this.connection_call = null
      this.varCallAcceppted = false
      this.calling = false
    },
    answerCall() {
      this.video_call = true
      this.calling = false
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audio = null
      this.callAcceppted()
    },
    closeMeet() {
      this.video_call = false
      this.varCallAcceppted = false
      if(this.connection_call)
        this.connection_call.send(JSON.stringify({ type: 'call_terminated' }))
      this.$store.dispatch('presence_ws/setStatus', 'free')
    },
    callAcceppted() {
      // console.log('call accepted')
      this.$store.dispatch('presence_ws/setStatus', 'engaged')
      this.varCallAcceppted = true
      this.connection_call.send(JSON.stringify({ type: 'call_accepted' }))
    },
    callClosed() {
      console.log('chiamata chiusa. Chiudo il canale')
      this.closeMeet()
    }
  }
}
</script>

<style>
  /* potresti imoprtarli anche nel file html */
  /* @import '@/assets/css/style.css';
  @import '@/assets/css/main-color.css'; */  .opacity {
    opacity: 0.2;
    transition: 1s;
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #fa3256;
    width: 140px;
    height: 140px;
    margin:auto;
    margin-top: 250px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  #custom_overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    cursor: pointer;
  }
  .meet_container {
    margin-top: 50px;
  }
  .header_meet {
    width: 1080px;
    height: 80px;
    background-color: #f6f6f6;
    margin: auto;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
  }
  .rl {
    color: #333;
    cursor: pointer;
    transition: .3s;
  }
  .rl:hover {
    color:rgb(249, 25, 66);
    transition: .3s;
  }
</style>
