<template>
  <div id="wrapper">
    <div class="text-center" v-if="loading" style="position:absolute; top: 5%; left: 48%; height: 500px;">
      <div class="loader"></div>
    </div>
    <HeaderCustom/>
    <div class="col-xs-12 col-md-12 text-center" v-if="!loading" style="text-align:center; padding-top:150px;">
      <h1 style="margin-bottom: 50px;">Lista Negozi</h1>
          <div v-for="(shop, i) in all_shops" :key="i">
            <h4><router-link class="rl" :to="'/shop?id='+shop.id">{{ shop.label }}</router-link></h4>
          </div>
    </div>
  </div>
</template>

<script>
import HeaderCustom from '@/components/Header'
import ShopService from '@/services/ShopService'
export default {
  name: 'all-shops-list',
  data() {
    return {
      loading: true,
      all_shops: [],
      error: null
    }
  },
  components: {
    HeaderCustom
  },
  async mounted() {
    this.$nextTick()
      .then(function () {
        let custom = document.createElement('script')
        custom.setAttribute('type', 'text/javascript')
        custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
        document.body.appendChild(custom)
      })
    var vm = this
    try {
      const all_shops = await ShopService.getAllShops()
      console.log('all shops', all_shops)
      this.all_shops = all_shops.data
    } catch(err) {
      console.log('err: ', error)
      error.response ? this.error = error.response.data.message : this.error = error + '! Ricarica la pagina'
    } finally {
      this.loading = false
    }
  }
}
</script>

<style scoped>
  .rl {
    color: #333;
    cursor: pointer;
    transition: .3s;
  }
  .rl:hover {
    color:rgb(249, 25, 66);
    transition: .3s;
  }
</style>