//////////////////////////////////////////////////

class classification {

    constructor( secs, cats ) {
        this.secs = secs;
        this.cats = cats;
    }

    get sectors() {
        var rows = JSON.parse( JSON.stringify( this.secs ) );
        rows.sort( ( a, b ) => {
          if ( a.name < b.name ){
            return -1;
          }
          if ( a.name > b.name ){
            return 1;
          }
          return 0;
        });
        return rows;
    }

    get categories() {
        var rows = this.cats.filter( x => x.hierarchyLevel === 1 );
        //console.log( rows.length );
        rows.sort( ( a, b ) => {
          if ( a.name < b.name ){
            return -1;
          }
          if ( a.name > b.name ){
            return 1;
          }
          return 0;
        });
        return rows;
    }

    get_subcategories( cat_id ) {
        var rows = [];
        this.cats.map( cat => {
            if ( cat.id === cat_id ) { rows = cat.children; }
        });
        return rows;
    }

    get_subcategory_by_id( subcat_id ) {
        for ( var i = 0; i < this.cats.length; i++ ) {
            if ( this.cats[ i ].hierarchyLevel === 2 && this.cats[ i ].id === subcat_id ) { return this.cats[ i ]; }
        }
        return null;
    }

}

module.exports = classification;

