<template>

    <Autocomplete
        ref            = "new_autocomplete"
        :options       = "{fields: ['geometry'], componentRestrictions: {country: 'it'}}"
        @place_changed = "set_place"
        @input.native  = "set_text"
        style          = "width: 100%; height: 51px; border: 1px solid #dbdbdb;">
    </Autocomplete>

</template>

<script>

import { Autocomplete } from 'vue2-google-maps';
import ParseLocationNewVersion from '@/utils/parse_geolocation_new_version.js'
export default {
    components: { Autocomplete },
    props: ['id_address'],
    data () {
        return {
            address : '',
            text    : '',
        }
    },
    watch: {
        text( new_value, old_value ) {
            if ( new_value.length === 0 ) {
                this.$emit( 'no-text', {} );
            }
        }
    },
    computed: {},
    mounted() {},
    created() {},
    methods: {
        set_text(event) {
            this.text = event.target.value
        },
        set_place(place) {
            this.stop = true
            this.address = ParseLocationNewVersion.main(place);
        },
    }
}

</script>

