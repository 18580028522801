export const presence_ws = {
  namespaced: true,
  state: {
    status: '',
    connection: null
  },
  mutations: {
    setStatus( state, status ) {
      state.status = status;
    },
    setConnection( state, connection ) {
      state.connection = connection;
    }
  },
  actions: {
    setStatus({ commit }, status) {
      commit('setStatus', status );
    },
    setConnection({ commit }, connection) {
      commit('setConnection', connection );
    }
  }
}