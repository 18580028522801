<template>
  <div>
    <div class="text-center" style="position:absolute; top: 70%; left: 50%; height: 500px;" v-if="loading || getting_result">
      <div class="loader"></div>
    </div>
    <div style="margin-top:81px;" :class="getting_result ? 'fs-container opacity' : ''" v-if="!loading">
      <Slider :gallery="shop.gallery"/>
      <div class="container">
        <div class="row sticky-wrapper">
          <div class="col-lg-8 col-md-8 padding-right-30">
            <div id="titlebar" class="listing-titlebar">
              <div class="listing-titlebar-title">
                <h2>
                  <img class="logo" :src="shop.logo ? config.backend_url+shop.logo.path : require('@/assets/images/noimage.png')" alt="no-image">
                  {{ shop.label }} <span class="listing-tag">{{ shop.mytypes[0].label }}</span>
                </h2>
                <span>
                  <a href="#listing-location" class="listing-address" v-if="!loading">
                    <i class="fa fa-map-marker"></i>
                    {{ shop.addresses && !noroute ? shop.addresses.filter(el => el.label === 'negozio')[0].geodata.formatted_address : 'Nessun indirizzo' }}
                  </a>
                </span>
                <!-- l'id dello shop va pescato dal param nell'url -->
                <!-- <span v-if="$store.state.user.isUserLoggedIn" :class="($store.state.my_shops.my_shops.map(el => el.id)).includes($store.state.shop.shop.id) ? 'like-icon liked' : 'like-icon'" @click="addRemoveFavoriteShop(shop)"></span> -->
              </div>
            </div>
            <div id="listing-overview" class="listing-section">
            </div>
          </div>


          <!-- Sidebar
          ================================================== -->
          <div class="col-lg-4 col-md-4 margin-top-75 sticky">
          </div>
        </div>
        <div class="col-xs-12">
          <iframe class="col-xs-12" style="height:100vh; border-widyh: 0px;" src="https://myfood.okkam.it/myfood-web/it/menus/eid-gen-8s3c0qm-86bluga-l61j2h8-p87d8hg" title="W3Schools Free Online Web Tutorials"></iframe>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from '@/components/Slider'
import config from '@/config'
import ListingService from '@/services/ListingService'
import ShopService from '@/services/ShopService'
export default {
  name: 'test-iframe',
  components: {
    Slider
  },
  data() {
    return {
      loading: true,
      getting_result: false,
      noroute: false,
      getting_result: false,
      config: null,
      center: {},
      days: ['Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato','Domenica'],
      shop: null
    }
  },
  created () {
    this.config = config
  },
  computed: {
    meta: function() {
      return this.shop.metas
    },
  },
  async mounted() {
    try {
      const shop = await ShopService.getShopMeta(this.$route.query.id)
      this.shop = shop.data
      // console.log('Pubblica: ', this.shop)
    } catch(error) {
      error.response ? console.log(error.response.data.message) : console.log(error + '! Ricarica la pagina')
      this.$router.push({
        path: '/'
      })
    }
    this.$nextTick()
      .then(function () {
        let custom = document.createElement('script')
        custom.setAttribute('type', 'text/javascript')
        custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
        // custom.async = true;
        let addtoany = document.createElement('script')
        addtoany.setAttribute('type', 'text/javascript')
        addtoany.setAttribute('src', 'https://static.addtoany.com/menu/page.js')
        addtoany.async = true
        document.body.appendChild(custom)
        document.body.appendChild(addtoany)
      })
    this.shop.logo = this.shop.logo.value
    this.shop.gallery = this.shop.gallery.map(el => el.value)
    if (this.shop.addresses) {
      if (this.shop.addresses.filter(el => el.label === 'negozio')[0].geodata) {
        this.center = this.shop.addresses.filter(el => el.label === 'negozio')[0].geodata.geometry.location
      } else {
        this.noroute = true
      }
    } else {
      this.noroute = true
    }
    this.loading = false
  }
}
</script>

<style>

</style>