export const my_shops = {
  namespaced: true,
  state: {
    my_shops: [],
  },
  mutations: {
    setMyShops (state, shops) {
      state.my_shops = shops
    }
  },
  actions: {
    setMyShops ({ commit }, shops) {
      commit('setMyShops', shops)
    }
  }
}