export const shop = {
  namespaced: true,
  state: {
    shop: null,
  },
  mutations: {
    setShop (state, shop) {
      state.shop = shop
    },
    setDataShop (state, data) {
      state.shop.label = data.label
      state.shop.partita_iva = data.partita_iva
      state.shop.codice_fiscale = data.codice_fiscale
      state.shop.codice_ateco = data.codice_ateco
      state.shop.ragione_sociale = data.ragione_sociale
      state.shop.descrizione = data.descrizione
    }
  },
  actions: {
    setShop ({ commit }, shop) {
      commit('setShop', shop)
    },
    setDataShop ({ commit }, data) {
      commit('setDataShop', data)
    }
  }
}