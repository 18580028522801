<template>
  <div>
    <input type="text" placeholder="Inserisci il nome del negozio che cerchi" v-model="search"/>
  </div>
</template>

<script>
export default {
  name: 'looking-for',
  data() {
    return {
      search: ''
    }
  }
}
</script>

<style>

</style>