<template>
  <div class="iframe" v-if="application">
    <h4 v-if="$route.path !== '/app-third-part'" class="back_home" @click="backHome()"><i class="im im-icon-Left-4"></i> Torna alla pagina negozio</h4>
    <div class="fs-container content">
      <h4>{{ application.value.label }}</h4>
      <!-- <p><i>{{ application.description }}</i></p> -->
      <!-- <iframe class="col-xs-12" :src="application.iframe" :title="application.name"></iframe> -->
      <iframe v-if="$route.path === '/app-third-part' ? application.value.admin_url : application.value.user_url" class="col-xs-12" :src="$route.path === '/app-third-part' ? application.value.admin_url : application.value.user_url" :title="application.value.label"></iframe>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'iframe',
  props: ['applications'],
  data() {
    return {
    }
  },
  async mounted() {
    this.$watch(
        () => {
            return this.applications.filter(el => el.name === this.$route.query.app)[0] ? this.applications.filter(el => el.name === this.$route.query.app)[0].value.user_iframe : 1
        },
      (val) => {
        if(!val) {
          console.log('NOn puoi essere qua')
          this.$eventHub.$emit('tmp_iframe_view_event', true)
        }
      }
    )
    if (this.$route.query.app) {
      if (!this.applications.filter(el => el.name === this.$route.query.app)[0]) {
        this.$eventHub.$emit('tmp_iframe_view_event', true)
      } else {
        if(!this.applications.filter(el => el.name === this.$route.query.app)[0].value.user_iframe) {
          this.$eventHub.$emit('tmp_iframe_view_event', true)
        }
      }
    }
  },
  methods: {
    backHome() {
      // this.$eventHub.$emit('iframe_view_event', true)
      this.$eventHub.$emit('tmp_iframe_view_event', true)
    }
  },
  computed: {
    application: function() {
      return this.applications ? this.applications.filter(el => el.name === this.$route.query.app)[0] : null
    }
  }
}
// this.$eventHub.$emit('highlight', index)
</script>

<style scoped>
  .back_home {
    color: #333;
    cursor: pointer;
    transition: .3s;
  }
  .back_home:hover {
    color: #f91942;
    transition: .3s;
    text-decoration: underline;
  }
  .content {
    margin-top: 25px;
  }
  .content iframe {
    border: none;
    height: 150vh;
  }
</style>