<template>
  <div style="position:fixed; top: 0; left: 0; right: 0; bottom: 0; width; 100%; height: 100%; z-index: 9999999;">
    <div class="col-xs-1 col-sm-3 col-md-4 custom_col-4"></div>
    <div class="call-animation col-xs-10 col-sm-6 col-md-3" style="margin-left:0px; margin-right:0px; padding:0;">
      <div class="col-xs-12" style="height: 40px; background: #333;">
        <p style="color:white;">{{ name }}</p>
      </div>
      <div class="col-xs-12" v-if="type === 'client'">
        <h2>Sta chiamando...</h2>
      </div>
      <div class="col-xs-12" v-else>
        <h3>Chiamata in arrivo da {{ name }}</h3>
      </div>
      <div class="col-xs-12 text-center call_controller">
        <p v-if="type === 'client'">Chiudi chiamata</p>
        <i class="im im-icon-Phone-2" style="font-size:40px; color: red; cursor: pointer;" @click="closeCall()"></i>
        <i v-if="type === 'shop'" class="im im-icon-Telephone" style="font-size:40px; color: green; cursor: pointer; margin-left:20px;" @click="answerCall()"></i>
      </div>
    </div>
    <div class="col-xs-1 col-sm-3 col-md-4"></div>
  </div>
</template>

<script>
export default {
  name: 'incoming-call',
  props: ['name', 'type'],
  data() {
    return {}
  },
  methods: {
    closeCall() {
      if(this.type === 'client') {
        this.$eventHub.$emit('close_call_client', true)
      } else {
        this.$eventHub.$emit('dont_answer_shop', true)
      }
    },
    answerCall() {
      this.$eventHub.$emit('answer_call', true)
    }
  }
}
</script>

<style scoped>
 .call-animation {
    /* position: fixed;
    top: 25%;
    left: 40%; */
    /* width: 300px; */
    margin-top: 100px;
    height: 500px;
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #f6f6f6;
  }
  .call_controller {
    position: absolute; bottom: 20px;
  }
  @media (min-width: 992px) {
    .custom_col-4 {
      width: 37% !important;
    }
  }
</style>