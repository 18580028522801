<template>
  <!-- Coming Soon Page -->
  <div class="coming-soon-page" style="background-image: url(images/main-search-background-01.jpg)">
    <div class="container">
      <!-- Search -->
      <div class="row">
        <div class="col-lg-8 col-lg-offset-2">
          <img src="@/assets/images/Logo Vetrineonline.com.png" alt="Vetrine Online">

          <h3>Stiamo per aprire!</h3>
          
          <!-- Countdown -->
          <div id="countdown" class="margin-top-10 margin-bottom-35"></div>
          <!-- Countdown / End -->

          <br>
          
        </div>
      </div>
      <!-- Search Section / End -->
    </div>
  </div>
<!-- Coming Soon Page / End -->
</template>

<script>
export default {
  name: 'cooming-soon',
  mounted() {
    this.$nextTick()
      .then(function () {
        let custom = document.createElement('script')
        custom.setAttribute('type', 'text/javascript')
        custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
        // custom.async = true;
        document.body.appendChild(custom)

        // let countdown = document.createElement('script')
        // countdown.setAttribute('type', 'text/javascript')
        // countdown.setAttribute('src', 'scripts/jquery.countdown.min.js')
        // document.body.appendChild(countdown)
      })
  }
}
</script>

<style>

</style>