<template>
  <div class="listing-slider mfp-gallery-container margin-bottom-0" v-if="get_slides.length > 0">
	<!-- <a href="images/single-listing-01.jpg" data-background-image="images/single-listing-01.jpg" class="item mfp-gallery" title="Title 1"></a>
	<a href="images/single-listing-02.jpg" data-background-image="images/single-listing-02.jpg" class="item mfp-gallery" title="Title 3"></a>
	<a href="images/single-listing-03.jpg" data-background-image="images/single-listing-03.jpg" class="item mfp-gallery" title="Title 2"></a>
	<a href="images/single-listing-04.jpg" data-background-image="images/single-listing-04.jpg" class="item mfp-gallery" title="Title 4"></a> -->
  <a
    v-for="(slide, i) in get_slides"
    :key="i"
    :href="slide.url"
    :data-background-image="slide.url"
    class="item mfp-gallery"
    :title="slide.name"
  >
  </a>
  <a
    v-if="get_slides.length === 2 || get_slides.length === 1"
    v-for="(slide, i) in get_slides"
    :key="i"
    :href="slide.url"
    :data-background-image="slide.url"
    class="item mfp-gallery"
    :title="slide.name"
  >
  </a>
  <a
    v-if="get_slides.length === 1"
    v-for="(slide, i) in get_slides"
    :key="i"
    :href="slide.url"
    :data-background-image="slide.url"
    class="item mfp-gallery"
    :title="slide.name"
  >
  </a>

</div>
</template>

<script>
import config from '@/config'
export default {
  name: 'slider',
  props: ['gallery'],
  data() {
    return {
      config: null
    }
  },
  created () {
    this.config = config
  },
  mounted() {
  },
  computed: {
    get_slides: function() {
      return this.gallery.map(el => {
        return {url: this.config.backend_url+el.url, name: el.original_name }
      })
    }
  }
}
</script>

<style>

</style>