export const shop_meta = {
  namespaced: true,
  state: {
    shop_meta: null,
  },
  mutations: {
    setShopMeta (state, shop_meta) {
      state.shop_meta = shop_meta
    }
  },
  actions: {
    setShopMeta ({ commit }, shop_meta) {
      commit('setShopMeta', shop_meta)
    }
  }
}