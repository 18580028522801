//////////////////////////////////////////////////

import service from '@/services/ClassificationService';
const classobj = require('@/utils/classification');

export const classification = {

    namespaced: true,
    state: {
        sectorsraw       : [],
        categoriesraw    : [],
        obj              : null,
        selected_ids     : [],
        classification   : {},
        selected_sectors : [],
    }, 
    getters: {
        sectors: ( state ) => state.obj.sectors,
        categories: ( state ) => state.obj.categories,
        subcategories: ( state ) => ( cat_id ) => { /*console.log( state.obj );*/ return state.obj.get_subcategories( cat_id ); },
    },
    actions: {
        async load_sectors( { commit } ) {
            await service.get_all('sector')
                .then( (res_sector) => {
                    commit('set_sectorsraw', res_sector.data);
                })
                .catch( error => { console.log( error ); } );
        },
        async load_categories( { commit } ) {
            await service.get_all('category', { include: true })
                .then( res_cat => {
                    commit('set_categoriesraw', res_cat.data);
                    commit('set_obj');
                });
        },
        async load_data({ commit, dispatch }) {
            await dispatch('load_sectors');
            await dispatch('load_categories');
            commit('reset_containers');
        },
        set_selected_ids( { commit }, ids ) { commit( 'set_selected_ids', ids ); },
        set_selected_sectors( { commit }, ids ) { commit( 'set_selected_sectors', ids ); },
        set_selected_ids_by_id( { commit, state }, ids, cat_id ) {
            var subcategories = [];
            if ( state.obj ) {
                ids.map( id => {
                    for ( var i = 0; i < state.obj.cats.length; i++ ) {
                        if ( state.obj.cats[ i ].hierarchyLevel === 2 && state.obj.cats[ i ].id === id ) {
                            subcategories.push({
                                id             : state.obj.cats[ i ].id,
                                label          : state.obj.cats[ i ].name,
                                hierarchyLevel : state.obj.cats[ i ].hierarchyLevel,
                                name           : state.obj.cats[ i ].name,
                                parentId       : state.obj.cats[ i ].parentId,
                            });
                        }
                    }
                });
            }
            commit( 'set_selected_ids', subcategories );
        },
        set_selected_sectors_by_id( { commit, state, getters }, ids ) {
            var sectors = [];
            if ( state.obj ) {
                ids.map( id => {
                    for ( var i = 0; i < getters.sectors.length; i++ ) {
                        if ( getters.sectors[ i ].id === id ) {
                            sectors.push({
                                id   : getters.sectors[ i ].id,
                                name : getters.sectors[ i ].name,
                            });
                        }
                    }
                });
            }
            commit( 'set_selected_sectors', sectors );
        },
        set_classification( { commit }, classification = {} ) { commit( 'set_classification', classification ); }
    },
    mutations: {
        set_sectorsraw( state, rows ) { state.sectorsraw = rows; },
        set_categoriesraw( state, rows ) { state.categoriesraw = rows; },
        set_obj( state ) { state.obj = new classobj( state.sectorsraw, state.categoriesraw ); },
        set_selected_ids( state, ids ) { state.selected_ids = ids },
        set_classification( state, classification ) { state.classification = classification; },
        set_selected_sectors( state, selected_sectors ) { state.selected_sectors = selected_sectors },
        reset_containers( state ) {
            state.classification   = {};
            state.selected_sectors = [];
            state.selected_ids     = [];
        },
    },

}

