import Api from '@/services/Api'
import axios from 'axios'
import config from '@/config'

export default {
  getTypeDefault() {
    return Api().get('/model/type/?order=label ASC')
  },
  getAppDefault() {
    return Api().get('/model/app?include=true')
  },
  getQRCodeMenu(shop_id, app_name) {
    return axios.get('https://myfood-api.okkam.org/okkam-food-api/IT/image/createQrCode?url='+config.frontend_url+'shop?id='+shop_id+'%26app='+app_name)
    // return axios.get('https://myfood-api.okkam.org/okkam-food-api/IT/image/createQrCode?url=http://localhost:8080/shop?id=10&app=menu')
  }
}