import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { user } from '@/store/user.module';
import { shop } from '@/store/shop.module';
import { shop_meta } from '@/store/shop_meta.module';
import { shop_address } from '@/store/shop_address.module';
import { shop_images } from '@/store/shop_images.module';
import { user_address } from '@/store/user_address.module';
import { user_meta } from '@/store/user_meta.module';
import { my_shops } from '@/store/my_shops.module';
import { presence_ws } from '@/store/presence_ws.module';
import { classification } from '@/store/classification.module';

// import { role } from '@/store/role.module';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  modules: {
    user,
    shop,
    shop_meta,
    shop_address,
    shop_images,
    user_address,
    user_meta,
    my_shops,
    presence_ws,
    classification,
    // role,
  }
})
