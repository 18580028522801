<template>
  <!-- Footer
  ================================================== -->
  <div id="footer" class="sticky-footer">
    <!-- Main -->
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-sm-6">
          <h4 style="color: grey">Vetrine OnLine</h4>
          <p>Vetrine OnLine è il primo sistema che ti permette di essere veramente in contatto con i negozi della tua città.</p>
        </div>

        <div class="col-md-4 col-sm-6 ">
          <!-- <h4>Mappa del Sito</h4>
          <ul class="footer-links">
            <li><a href="#sign-in-dialog">Login</a></li>
            <li><a href="#sign-in-dialog">Sign Up</a></li>
            <li><a href="#">My Account</a></li>
            <li><a href="#">Add Listing</a></li>
            <li><a href="#">Pricing</a></li>
            <li><a href="#">Privacy Policy</a></li>
          </ul> -->

          <!-- <ul class="footer-links">
            <li><a href="#">FAQ</a></li>
            <li><a href="#">Blog</a></li>
            <li><a href="#">Our Partners</a></li>
            <li><a href="#">How It Works</a></li>
            <li><a href="#">Contact</a></li>
          </ul> -->
          <!-- <div class="clearfix"></div> -->
        </div>		

        <div class="col-md-3  col-sm-12">
          <!-- <h4>Contatti</h4>
          <div class="text-widget">
            <span>12345 Little Lonsdale St, Melbourne</span> <br>
            Phone: <span>(123) 123-456 </span><br>
            E-Mail:<span> <a href="#">office@example.com</a> </span><br>
          </div>

          <ul class="social-icons margin-top-20">
            <li><a class="facebook" href="#"><i class="icon-facebook"></i></a></li>
            <li><a class="twitter" href="#"><i class="icon-twitter"></i></a></li>
            <li><a class="gplus" href="#"><i class="icon-gplus"></i></a></li>
            <li><a class="vimeo" href="#"><i class="icon-vimeo"></i></a></li>
          </ul> -->

        </div>

      </div>
      
      <!-- Copyright -->
      <div class="row">
        <div class="col-md-12">
          <div class="copyrights" style="font-size:12px;">
            <!-- <div class="col-xs-12 text-center">
              CONFCOMMERCIO SERVIZI DELLA CITTA' DELLA RIVIERA DEL BRENTA S.R.L.
            </div>
            <div class="col-xs-12 text-center">
                sede legale: Viale Ancona 9 30172 Mestre Ve
            </div>
            <div class="col-xs-12 text-center">
              sedi operative:
            </div>
            <div class="col-xs-12 text-center">
              Via Venezia 8 30034 Mira VE
            </div>
            <div class="col-xs-12 text-center">
              Via Benedetto Cairolo 18/D  30031 Dolo VE
            </div> -->
            <div class="col-xs-12 text-center">
              C.f.  e P.Iva 04498740275
            </div>
            <!-- <div class="col-xs-12 text-center">
              codice sdi  6EWHWLT
            </div> -->
            <div class="col-xs-12 text-center">
              <a class="privacy_policy_footer iubenda-black iubenda-embed" href="https://www.iubenda.com/privacy-policy/82105399" title="Privacy Policy ">Privacy e Policy</a>
            </div>
            <div class="col-xs-12 text-center">
              <router-link to="/all-shops">Tutti i Negozi</router-link>
            </div>
            <div class="col-xs-12 text-center">
              <a style="color: grey" href="mailto:assistenza@vetrineonline.com">assistenza@vetrineonline.com</a></h4>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  <!-- Footer / End -->
</template>

<script>
export default {
  name: 'footer'
}
</script>

<style scoped>
  @media screen and (max-width: 480px) {
    .text-custom-right {
      text-align: left;
    }
  }
  @media screen and (min-width: 481px) {
    .text-custom-right {
      text-align: right;
    }
  }
</style>
