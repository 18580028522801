<script>

import ShopService from '@/services/ShopService'

function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export default {
    name: 'token-converter',
    data () {
        return {
            msg    : 'verifica del token...',
            error  : null,
            routes : {
                shop : 'dashboard-company-my-shop',
                user : 'dashboard-company-my-profile',
            },
        }
    },
    async created() {
    },
    methods: {
    },
    components: {
    },
    async mounted() {
        try { var oldtoken = this.$route.query.jwt } catch ( error ) { this.error = 'token non trovato'; return; }
        try { var id       = this.$route.query.id } catch ( error ) { this.error = 'id non trovato'; return; }
        try { var type     = this.$route.query.type } catch ( error ) {}
        if ( !type ) {
            var type = 'shop';
        } else {
            if ( Object.keys( this.routes ).includes( type ) === false ) {
                this.error = 'il parametro type deve essere incluso nelle tipologie previste (shop oppure user).'
                return;
            }
        }
        var route = this.routes[ type ];
        // autenticandomi con il token richiedo un token per l'amministratore del negozio x
        await sleep(1000);
        var { user, token } = await ShopService.convertToken( oldtoken, id, type )
            .then( res => {
                return res.data;
            })
            .catch( error => {
                this.error = error.response.data.msg;
                return {};
            });
        if ( !user ) { return; }
        this.msg = 'impostazione sessione...';
        await sleep(1000);
        this.$store.dispatch('user/setToken', token) // serve?
        this.$store.dispatch('user/setUser', user)
        this.$store.dispatch('user_address/setAddress', user.addresses)
        this.$store.dispatch('user_meta/setUserMeta', user.metas)
        this.$store.dispatch('my_shops/setMyShops', user.myshops)
        if ( user.role_id === 5) {
            this.$store.dispatch('shop/setShop', user.myworkshops[0].api)
            this.$store.dispatch('presence_ws/setStatus', 'free')
        }
        this.msg = 'redirect to vetrineonline.com...';
        await sleep(1000);
        this.$router.push(`/${ route }`);
    },
    computed: {
    }
}

</script>

<template>

    <div style="text-align: center; margin-top: 200px;">
        <h3>Stai per essere reindirizzato alla pagina richiesta</h3>
        <div style="height: 300; vertical-align: center">
            <img v-if="error === null" src="@/assets/loader.gif" alt="Vetrine Online Loader">
            <img v-else-if="error" src="@/assets/alert.jpg" alt="Vetrine Online Alert">
        </div>
        <p v-if="error === null">{{ msg }}</p>
        <p v-else-if="error" style="color: red; font-weight: 900">{{ error }}</p>
    </div>

</template>

