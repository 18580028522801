<template>
  <div id="page_shop">
    <HeaderCustom/>
    <div class="text-center" style="position:absolute; top: 70%; left: 50%; height: 500px;" v-if="loading || getting_result">
      <div class="loader"></div>
    </div>
    <!-- <div class="col-xs-12 text-center">
      <h2>Devi prima impostare la via del negozio</h2>
    </div> -->
    <div :class="getting_result ? 'fs-container opacity mt-custom' : 'mt-custom'" v-if="!loading">
      <Slider :gallery="shop.gallery"/> 
        <!--objs.sort((a,b) => (a.id > b.id) ? -1 : ((b.id > a.id) ? 1 : 0))-->
      <div class="container">
        <div class="row sticky-wrapper">
          <div class="col-lg-8 col-md-8 padding-right-30">
            <div id="titlebar" class="listing-titlebar">
              <div class="listing-titlebar-title">
                <h2>
                  <img class="logo" :src="shop.logo ? config.backend_url+shop.logo.path : require('@/assets/images/noimage.png')" alt="no-image">
                  {{ shop.label }} <span class="listing-tag">{{ shop.mytypes[0].label }}</span>
                </h2>
                <span>
                  <a href="#listing-location" class="listing-address" v-if="!loading">
                    <i class="fa fa-map-marker"></i>
                    {{ shop.addresses && !noroute ? shop.addresses.filter(el => el.label === 'negozio')[0].geodata.formatted_address : 'Nessun indirizzo' }}
                  </a>
                </span>
                <!-- l'id dello shop va pescato dal param nell'url -->
                <!-- <span v-if="$store.state.user.isUserLoggedIn" :class="($store.state.my_shops.my_shops.map(el => el.id)).includes($store.state.shop.shop.id) ? 'like-icon liked' : 'like-icon'" @click="addRemoveFavoriteShop(shop)"></span> -->
              </div>
            </div>

            <div v-show="!iframe_view && !tmp_iframe">
              <!-- Listing Nav -->
              <div id="listing-nav" class="listing-nav-container">
                <ul class="listing-nav">
                  <li><a href="#listing-overview" class="active">Descrizione</a></li>
                  <!-- <li><a href="#app_third_part">Estensioni</a></li> -->
                  <!-- <li><a href="#listing-pricing-list"></a></li> -->
                  <li><a href="#listing-location">Dove siamo</a></li>
                  <li><a href="#listing-payments">Modalità di pagamento</a></li>
                  <li><a href="#listing-delivery">Modalità di consegna</a></li>
                </ul>
              </div>

              <!-- Overview -->
              <div id="listing-overview" class="listing-section">

                <!-- Description -->

                <p style="white-space: pre-line;">{{ shop.descrizione ? shop.descrizione : 'Nessuna descrizione' }}<p>
                
                
                <!-- Listing Contacts -->
                <div class="listing-links-container">

                   <ul class="listing-links contact-links" ><!-- '}-->
                    <li v-if="meta.contacts.value.telefono"><a :href="'tel:'+meta.contacts.value.telefono" class="listing-links"><i class="fa fa-phone" style="font-size:15px"></i><b style="font-size:17px"> {{ meta.contacts.value.telefono }}</b></a></li>
                    <li v-if="meta.contacts.value.cellulare"><a :href="'tel:'+meta.contacts.value.cellulare" class="listing-links"><i class="im im-icon-Smartphone-3" style="font-size:15px"></i><b style="font-size:17px"> {{ meta.contacts.value.cellulare }}</b></a></li>
                    <li v-if="meta.contacts.value.whatsapp"><a :href="'https://wa.me/39'+meta.contacts.value.whatsapp" target="_blank" class="listing-links"><i class="fa fa-whatsapp" style="color:green;font-size:15px"></i><b style="font-size:17px"> {{ meta.contacts.value.whatsapp }}</b></a></li>
                    <li v-if="meta.contacts.value.email"><a :href="'mailto:'+meta.contacts.value.email" class="listing-links"><i class="fa fa-envelope-o" style="color:red;font-size:15px"></i><b style="font-size:17px"> {{ meta.contacts.value.email }}</b></a></li>
                    <li v-if="meta.contacts.value.fax"><a class="listing-links"><i class="im im-icon-Fax" style="font-size:17px"></i><b style="font-size:17px"> {{ meta.contacts.value.fax }}</b></a></li>
                    <!--<li v-if="meta.contacts.value.web_url"><a :href="meta.contacts.value.web_url" target="_blank" class="listing-links"><i class="fa fa-link"></i><b>Website</b></a></li>--> <!-- {{ meta.contacts.value.web_url }} -->
                    <li v-if="meta.contacts.value.facebook_url"><a :href="meta.contacts.value.facebook_url" target="_blank" class="listing-links"><i class="fa fa-facebook-square" style="font-size:15px;color:blue"></i><b style="font-size:17px">Facebook</b></a></li> <!--{{ meta.contacts.value.facebook_url }} -->
                    <li v-if="meta.contacts.value.instagram_url"><a :href="meta.contacts.value.instagram_url" target="_blank" class="listing-links"><i class="fa fa-instagram" style="font-size:15px;color:darkred"></i><b style="font-size:17px">Instagram</b></a></li> <!-- {{ meta.contacts.value.instagram_url }} -->
                  </ul>
                  
                  <div class="clearfix"></div>

                  <div v-if="meta.contacts.value.web_url" class="verified-badge with-tip" :data-tip-content="'  '+ meta.contacts.value.web_url+'  '" style="cursor:pointer;padding: 15px 32px;text-align: center; text-decoration: none;display: inline-block;font-size: 16px;margin: 4px 2px cursor: pointer;">
                    <a :href="meta.contacts.value.web_url" target="_blank"><i class="fa fa-globe" style="color:white"></i><b style="color:white">  Website</b></a>
                  </div>
                  
                  <!-- AddToAny BEGIN -->
                  <!--Per condividere
                  <ul class="a2a_kit a2a_kit_size_32 a2a_default_style listing-links">
                    <li><a class="a2a_button_facebook"></a></li>
                    <li><a class="a2a_button_twitter"></a></li>
                    <li><a class="a2a_button_email"></a></li>
                    <li><a class="a2a_button_linkedin"></a></li>
                    <li><a class="a2a_button_whatsapp"></a></li>
                    <li><a class="a2a_button_telegram"></a></li>
                    <li><a class="a2a_dd" href="https://www.addtoany.com/share"></a></li>
                  </ul>
                  -->
                  <!-- <script async src="https://static.addtoany.com/menu/page.js"></script> -->
                  <!-- AddToAny END -->
                  
                </div>
                <div class="clearfix"></div>
              </div>

              <!-- App terze parti -->
              <!-- <div id="app_third_part" class="listing-section">
                <h3 class="listing-desc-headline margin-top-60 margin-bottom-30">Estensioni</h3>
                <div :class="app_third.length > 2 ? 'show-more' : ''">
                  <div class="pricing-list-container">
                    <h4>Lista di applicazioni</h4>
                    <ul>
                      <li v-for="(app, indx) in app_third" :key="indx" class="app_third" @click="openIframe(app)">
                        <h5>{{ app.name }}</h5>
                        <p>{{ app.description }}</p>
                        <span><i class="im im-icon-Right-4"></i></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <a v-show="app_third.length > 2" href="#" class="show-more-button" data-more-title="Mostra tutti" data-less-title="Mostra meno"><i class="fa fa-angle-down"></i></a>
                <div class="clearfix"></div>
              </div> -->

              <!-- Location -->
              <div id="listing-location">
                <h3 class="listing-desc-headline margin-top-60 margin-bottom-30">Dove siamo</h3>
                <!-- Map -->
                <div id="map-container" v-if="!noroute && !loading">
                  <GmapMap
                    ref="map"
                    :center="center"
                    :zoom="16"
                    style="width: 100%; height: 100%"
                  >
                    <GmapMarker
                      :position="center"
                      :clickable="false"
                      :draggable="false"
                      icon="http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                      :animation="2"
                    />
                  </GmapMap>
                </div>
                <div v-else>
                  Nessun Indirizzo Specificato
                </div>
              </div>

              <!-- Modalità di pagamento -->
              <div id="listing-payments">
                <h3 class="listing-desc-headline margin-top-60 margin-bottom-30">Modalità di pagamento</h3>
                  <div class="rating-overview" style="margin-bottom: 50px;">
                    <!-- <ul>
                      <li v-for="(item, i) in meta.payment_types.value" :key="i">
                          <span class="form-check-label" style="margin-left: 20px;"><i :class="item.label === 'PayPal' ? 'fa fa-paypal' : item.label === 'Credit Card' ? 'im im-icon-Credit-Card2' : item.label === 'Cash' ? 'im im-icon-Money-2' : 'sl sl-icon-settings'"></i> {{ item.label }} </span>
                      </li>
                    </ul> -->
                    <div class="col-xs-12 nopadding" >
                      <div class="col-xs-12 col-md-3 nopadding" v-for="(item, i) in meta.payment_types.value.filter(el => el.enabled)" :key="i">
                          <span class="form-check-label" style="margin-left: 20px; font-size:18px;"><i :class="item.label === 'PayPal' ? 'fa fa-paypal' : item.label === 'Credit Card' ? 'im im-icon-Credit-Card2' : item.label === 'Cash' ? 'im im-icon-Money-2' : 'sl sl-icon-settings'"></i> {{ item.label === 'Credit Card' ? 'Carta' : item.label === 'Cash' ? 'Contanti' : item.label}} </span>
                      </div>
                    </div>
                  </div>
              </div>

              <!-- Modalità di consegna -->
              <div id="listing-delivery">
                <h3 class="listing-desc-headline margin-top-60 margin-bottom-30">Modalità di consegna</h3>
                  <div class="rating-overview" style="margin-bottom: 50px;">
                    <!-- <ul>
                      <li v-for="(item, i) in meta.delivery_types.value" :key="i">
                          <span class="form-check-label" style="margin-left: 20px;"><i :class="item.label === 'In Negozio' ? 'im im-icon-Shop-2' : item.label === 'A domicilio' ? 'im im-icon-Home-5' : 'sl sl-icon-settings'"></i> {{ item.label }} </span>
                      </li>
                    </ul> -->
                    <div class="col-xs-12 nopadding" >
                      <div class="col-xs-12 col-md-3 nopadding" v-for="(item, i) in meta.delivery_types.value.filter(el => el.enabled)" :key="i">
                          <span class="form-check-label" style="margin-left: 20px; font-size:18px;"><i :class="item.label === 'In Negozio' ? 'im im-icon-Shop-2' : item.label === 'A domicilio' ? 'im im-icon-Home-5' : 'sl sl-icon-settings'"></i> {{ item.label }} </span>
                      </div>
                    </div>
                  </div>
              </div>

              
              <h3 class="margin-bottom-20" style="text-decoration: underline;" @click="showLegalInfo ^= true">Informazioni legali</h3>
              <div class="rating-overview" style="margin-bottom: 50px;"  v-show="showLegalInfo">
              <p>
                <span v-if="this.shop.ragione_sociale != null && this.shop.ragione_sociale != ''">Ragione sociale: {{this.shop.ragione_sociale}}</span><br>
                Codice Fiscale: {{this.shop.codice_fiscale}} <br>
                Partita IVA: {{this.shop.partita_iva}}<br>
                <span v-if="this.shop.rna"><b v-if="this.shop.ragione_sociale != null && this.shop.ragione_sociale != ''">{{this.shop.ragione_sociale}},</b> <b>{{this.shop.codice_fiscale}}</b> adempie agli obblighi pubblicitari previsti dalla legge 124/2017 indicando gli aiuti percepiti nel <a href="https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/TrasparenzaAiuto.jspx" target="_blank">Registro Nazionale Degli Aiuti di Stato</a>.</span>
              </p>
              </div>
            </div>
          </div>
          <!-- Sidebar
          ================================================== -->
          <div class="col-lg-4 col-md-4 margin-top-75 st
          icky" v-show="!iframe_view && !tmp_iframe">
            <!-- <div v-if="get_Bvideocall_visibility" :class="isFree === 'free' ? 'verified-badge with-tip video_call' : 'verified-badge with-tip video_disabled'" :data-tip-content="isFree === 'free' ? 'Avvia la video chiamata con '+shop.label : isFree === 'not-connected' ? 'Non disponibile' : 'Non disponibile'" @click="videoCall()"> -->
            <div v-if="get_Bvideocall_visibility" :class="isFree === 'free' ? 'verified-badge with-tip video_call' : 'verified-badge with-tip video_disabled'" :data-tip-content="'Video chiamata con '+shop.label" @click="videoCall()">
              <i class="im im-icon-Video"></i> Video chiama
            </div>
            <br>
            <div v-for="(app, index) in app_third" :key="index" style="margin-bottom:20px;">
              <div class="verified-badge with-tip" :data-tip-content="app.name === 'myfood_listino' ? 'Vai alla sezione menu di '+shop.label : 'Vai alla sezione di '+app.value.label" style="cursor:pointer;" @click="openIframe(app)">
                <i :class="app.name === 'myfood_listino' ? 'im im-icon-Bulleted-List' : 'im im-icon-Neutron'"></i> {{app.value.label}}
              </div>
            </div>
            <!-- Orari di Apertura -->
            <div class="boxed-widget opening-hours margin-top-35" v-if="showOrari('apertura')">
              <div class="listing-badge now-open">Apertura</div>
              <h3><i class="sl sl-icon-clock"></i> Orari di Apertura</h3>
              <ul>
                <li v-for="(item, i) in days" :key="i" class="col-xs-12" style="padding-left:0px; padding-right:0px; border-bottom:1px solid #e0e0e0; padding-top:10px; padding-bottom:10px;">
                  <span class="col-xs-12 col-md-3" style="padding-left:0px; padding-right:0px; float:left;">{{ item }}</span>
                  <span v-if="meta.timetable.value.filter(el => (el.days).includes(i+1)).length > 0" class="col-xs-12 col-md-9" style="padding-left:0px; padding-right:0px;">
                    <span
                    class="col-xs-12"
                    style="text-align: right; padding-left:0px; padding-right:0px;"
                    v-if="meta.timetable.value.filter(el => (el.days).includes(i+1))[0].times.length > 0"
                    v-for="(time, index) in meta.timetable.value.filter(el => (el.days).includes(i+1))[0].times" :key="index"
                    >
                      <span>{{ time.from + ' - ' + time.to }}</span>
                    </span>
                  </span>
                  <span v-else style="text-align: right;">Chiuso</span>
                </li>
              </ul>
            </div>
            <!-- Orari di Apertura/ End -->

            <!-- Orari di Consegna -->
            <div class="boxed-widget opening-hours margin-top-35" v-if="showOrari('consegna')">
              <div class="listing-badge delivery">Consegna</div>
              <h3><i class="sl sl-icon-clock"></i> Orari di Consegna</h3>
              <ul>
                <li v-for="(item, i) in days" :key="i" class="col-xs-12" style="padding-left:0px; padding-right:0px; border-bottom:1px solid #e0e0e0; padding-top:10px; padding-bottom:10px;">
                  <span class="col-xs-12 col-md-3" style="padding-left:0px; padding-right:0px; float:left;">{{ item }}</span>
                  <span v-if="meta.delivery_timetable.value.filter(el => (el.days).includes(i+1)).length > 0" class="col-xs-12 col-md-9" style="padding-left:0px; padding-right:0px;">
                    <span
                    class="col-xs-12"
                    style="text-align: right; padding-left:0px; padding-right:0px;"
                    v-if="meta.delivery_timetable.value.filter(el => (el.days).includes(i+1))[0].times.length > 0"
                    v-for="(time, index) in meta.delivery_timetable.value.filter(el => (el.days).includes(i+1))[0].times" :key="index"
                    >
                      <span>{{ time.from + ' - ' + time.to }}</span>
                    </span>
                  </span>
                  <span v-else style="text-align: right;">Non disponibile</span>
                </li>
              </ul>
            </div>
            <!-- Orari di Apertura/ End -->

            <!-- Orari di Chiamata -->
            <div class="boxed-widget opening-hours margin-top-35" v-if="showOrari('chiamata')">
              <div class="listing-badge calling">Chiamata</div>
              <h3><i class="sl sl-icon-clock"></i> Orari di Chiamata</h3>
              <ul>
                <li v-for="(item, i) in days" :key="i" class="col-xs-12" style="padding-left:0px; padding-right:0px; border-bottom:1px solid #e0e0e0; padding-top:10px; padding-bottom:10px;">
                  <span class="col-xs-12 col-md-3" style="padding-left:0px; padding-right:0px; float:left;">{{ item }}</span>
                  <span v-if="meta.contact_timetable.value.filter(el => (el.days).includes(i+1)).length > 0" class="col-xs-12 col-md-9" style="padding-left:0px; padding-right:0px;">
                    <span
                    class="col-xs-12"
                    style="text-align: right; padding-left:0px; padding-right:0px;"
                    v-if="meta.contact_timetable.value.filter(el => (el.days).includes(i+1))[0].times.length > 0"
                    v-for="(time, index) in meta.contact_timetable.value.filter(el => (el.days).includes(i+1))[0].times" :key="index"
                    >
                      <span>{{ time.from + ' - ' + time.to }}</span>
                    </span>
                  </span>
                  <span v-else style="text-align: right;">Non disponibile</span>
                </li>
              </ul>
            </div>
            <!-- Orari di Apertura/ End -->
            <div class="listing-share margin-top-40 margin-bottom-40 no-border" v-if="$store.state.user.isUserLoggedIn">
              <button @click="addRemoveFavoriteShop()" class="like-button"><span :class="liked ? 'like-icon liked' : 'like-icon'"></span> Inserisci tra i preferiti</button> 
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
        <div v-show="tmp_iframe">
          <!-- <iframe class="col-xs-12" style="height:100vh; border-widyh: 0px;" src="https://myfood.okkam.it/myfood-web/it/menus/eid-gen-8s3c0qm-86bluga-l61j2h8-p87d8hg" title="W3Schools Free Online Web Tutorials"></iframe> -->
          <IframeCustom :applications="app_third"/>
        </div>
        <!-- <div v-show="tmp_iframe">
          <TMPIframe :volid="shop.partita_iva"/>
        </div> -->
      </div>
      <IncomingCall :name="shop.label" :type="'client'" v-if="calling"/>
      <VideoCall style="z-index:99999;" v-if="video_call" :shop_name="shop.label" :first_name="$store.state.user.user.first_name" :url="hash_url_videocall"/>

    <FooterCustom/>
  </div>
  </div>
</template>
<script>
var sha256 = require('js-sha256');
import HeaderCustom from '@/components/Header'
import FooterCustom from '@/components/Footer'
import Slider from '@/components/Slider'
import IframeCustom from '@/components/Iframe'
import TMPIframe from '@/components/TMPIframe'
import IncomingCall from '@/components/IncomingCall'
import VideoCall from '@/views/VideoCall'
import config from '@/config'
import ListingService from '@/services/ListingService'
import ShopService from '@/services/ShopService'
export default {
  name: 'page-shop',      
  components: {
    HeaderCustom,
    FooterCustom,
    Slider,
    IframeCustom,
    TMPIframe,
    VideoCall,
    IncomingCall
  },
  data() {
    return {
      loading: true,
      getting_result: false,
      noroute: false,
      getting_result: false,
      config: null,
      center: {},
      days: ['Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato','Domenica'],
      shop: null,
      app_third: [],
      iframe_view: false,
      application_selected: null,
      connection: null,
      connection_call: null,
      video_call: false,
      status_shop: {},
      hash_url_videocall: '',
      notAnswered: false,
      calling: false,
      showLegalInfo: false
    }
  },
  metaInfo() {
    return {
      title: this.metaGetTitle,
      meta: [
        { name: 'description', content: this.metaGetDescription },
        { name: 'robots', content: "index, follow" },
        { property: 'og:title', content: this.metaGetTitle },
        { property: 'og:image', content: this.metaGetImage },
        { property: 'og:url', content: this.metaGetUrl }
      ]
    }
  },
  created () {
    this.$eventHub.$on('iframe_view_event', (value) => {
      this.iframe_view = false
      this.application_selected = null
    })
    this.$eventHub.$on('tmp_iframe_view_event', (value) => {
      // this.$router.push({ query: { id: this.shop.id }})
      this.$router.push({
        path: '/shop?id='+this.shop.id
      })
    })
    this.$eventHub.$on('close_call', (value) => {
      this.video_call = false
      this.connection_call.send(JSON.stringify({ type: 'call_terminated' }))
    })
    this.$eventHub.$on('close_call_client', this.closeCall)
    this.$eventHub.$on('call_closed_client', this.callClosed)
    this.config = config
  },
  beforeDestroy () {
    this.$eventHub.$off('iframe_view_event')
    this.$eventHub.$off('tmp_iframe_view_event')
    this.$eventHub.$off('close_call')
    this.$eventHub.$off('close_call_client')
    this.$eventHub.$off('call_closed_client')
    if(this.connection)
      this.connection.close()
    if(this.connection_call)
      this.connection_call.close()
  },
  computed: {
    meta: function() {
      return this.shop.metas
    },
    liked: function() {
      return this.$store.state.user.isUserLoggedIn ? this.$store.state.my_shops.my_shops.map(el => el.id).includes(this.shop.id) : false
    },
    // app_third: function() {
    //   return this.$store.state.shop_meta.shop_meta.thrd_part_apps.value
    // },
    tmp_iframe: function() {
      return this.$route.query.app ? true : false
    },
    get_Bvideocall_visibility: function() {
      if (this.$store.state.user.isUserLoggedIn) {
        if (this.$store.state.shop.shop) {
          if (this.$store.state.shop.shop.id === parseInt(this.$route.query.id)) {
            // console.log('Sei qua????')
            return false
          }
        }
        return true
      } else {
        return false
      }
    },
    isFree: function() {
      return this.status_shop
    },
    metaGetTitle: function() {
      return this.shop ? this.shop.label + ' - ' + this.shop.mytypes[0].label + ' - Vetrine Online' : ''
    },
    metaGetDescription: function() {
      return this.shop ? this.shop.descrizione : ''
    },
    metaGetImage: function() {
      return this.shop ? this.shop.logo ? this.config.backend_url+this.shop.logo.path : this.config.frontend_url+require('@/assets/images/noimage.png') : ''
    },
    metaGetUrl: function() {
      return this.config.frontend_url+'shop?id='+this.$route.query.id
    }
  },
  async mounted() {
    // IN SHOP MI IMMAGINO CI SARANNO TUTTE LE APP DI TERZE PARTI!!!!! PER ORA LE METTIAMO DENTRO app_third
    if (this.$route.path === '/preview-myshop') {
      // this.shop = this.$store.state.shop.shop
      const shop = await ShopService.getShopMeta(this.$store.state.shop.shop.id)
      this.shop = shop.data
    } else {
      try {
        const shop = await ShopService.getShopMeta(this.$route.query.id /*this.$route.params.id*/)
        this.shop = shop.data
        console.log("stampo this.shop "+this.shop.ragione_sociale)
        console.log(this.shop)
        this.app_third = shop.data.metas.thrd_part_apps.value.filter(app => app.value.user_iframe)
        console.log('SHOP: ', this.shop)
      } catch(error) {
        error.response ? console.log(error.response.data.message) : console.log(error + '! Ricarica la pagina')
        this.$router.push({
          path: '/'
        })
        return 0
      }
    }
    this.$nextTick()
      .then(function () {
        let custom = document.createElement('script')
        custom.setAttribute('type', 'text/javascript')
        custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
        // custom.async = true;
        let addtoany = document.createElement('script')
        addtoany.setAttribute('type', 'text/javascript')
        addtoany.setAttribute('src', 'https://static.addtoany.com/menu/page.js')
        addtoany.async = true
        document.body.appendChild(custom)
        document.body.appendChild(addtoany)
      })
    this.shop.logo = this.shop.logo.value
    this.shop.gallery = this.shop.gallery.map(el => el.value)
    if (this.shop.addresses) {
      if (this.shop.addresses.filter(el => el.label === 'negozio')[0].geodata) {
        this.center = this.shop.addresses.filter(el => el.label === 'negozio')[0].geodata.geometry.location
      } else {
        this.noroute = true 
      }
    } else {
      this.noroute = true
    }
    // for(let i = 0; i<4; i++) {
    //   this.app_third.push({name: 'Prenotazione', description: 'Prenota online il nostro menu ecc..', iframe: 'https://myfood.okkam.it/myfood-web/it/menus/eid-gen-8s3c0qm-86bluga-l61j2h8-p87d8hg'})
    // }

    // Websocket
    if(this.$store.state.user.isUserLoggedIn) {
      if (this.$store.state.shop.shop) {
        if (this.$store.state.shop.shop.id === parseInt(this.$route.query.id))
          console.log('No Ws connection')
        else {
          this.connectionWS()
        }
      } else {
        this.connectionWS()
      }
    } else {
      console.log('No Ws connection')
    }
    if (this.$store.state.user.isUserLoggedIn) {
      console.log('HASH', sha256(this.$route.query.id+'_'+this.$store.state.user.user.id+'_supercalifragili'))
      this.hash_url_videocall = sha256(this.$route.query.id+'_'+this.$store.state.user.user.id+'_supercalifragili')
    }
    var vm = this
    this.$watch(
      () => {
          return this.$store.state.user.isUserLoggedIn
      },
      (val) => {
        if(val) {
          // console.log('LOGGATO. START: ', val)
          // // this.connectionWS()
        } else {
          console.log('chiudo le connessione')
          if(vm.connection) {
            this.connection.close()
          }
          if(vm.connection_call) {
            this.connection_call.close()
          }
        }
      }
    )

    this.loading = false
  },
  methods: {
    
    showOrari(tipo_orario) {
      let orario = ''
      switch (tipo_orario) {
        case "apertura":
          orario = 'timetable'
          break
        case "chiamata":
          orario = 'contact_timetable'
          break
        case "consegna":
          orario = 'delivery_timetable'
          break
        default: return
      }
      let bool = false
      for (let i = 0; i<this.days.length; i++) {
        if (this.meta[orario].value.filter(el => (el.days).includes(i+1)).length > 0) {
          bool = true
          break
        }
      }
      // if(bool) {
      //   console.log('ce nè almeno uno')
      // } else {
      //   console.log('nessun orario')
      // }
      return bool

    },
    connectionWS() {
      var shop_id = this.$route.query.id
      var url = config.backend_url.replace('http', 'ws') + 'ws/check?shop_id=' + shop_id
      console.log('url: ', url)
      var vm = this
      try {
        this.connection = new WebSocket(url)
      } catch(error) {
        console.log('errore durante la connessione, ristabilisco la connessione...')
        setTimeout(() => {
          vm.connectionWS()
          return
        }, 3000)
      }
      
      var ws = this.connection
      ws.onerror = function() {
        console.log('Connection Error');
      }

      ws.onclose = function() {
        console.log('echo-protocol Client Closed');
        this.connection = null
        var loggedIn = vm.$store.state.user.isUserLoggedIn
        var path = vm.$route.path
        setTimeout(() => {
          if(loggedIn && path === '/shop') {
            console.log('riconnessione...')
            vm.connectionWS()
          }
          else {
            console.log('Non ti riconnetto')
            ws.close()
            return
          }
        }, 5000)
        return
      }

      ws.onopen = function(event) {
        console.log('websocket CHECK is connected ...');
        console.log(event)
        function checkStatus() {
          if (vm.connection && vm.$route.path === '/shop') {
            // console.log('ask presence')
            ws.send(JSON.stringify({ type: 'ask_presence' }));
            setTimeout(checkStatus, 5000);
          } else {
            return
          }
        }
        checkStatus();
      }

      ws.onmessage = function(e) {
        // console.log("Received CHECK: '" + e.data + "'")
        vm.status_shop = JSON.parse(e.data).status
      }
    },
    videoCallWS() {
      var shop_id = this.$route.query.id
      var user_name = this.$store.state.user.user.first_name
      var url = config.backend_url.replace('http', 'ws') + 'ws/call/?shop_id=' + shop_id + '&room_name=' + this.hash_url_videocall + '&user_name=' + user_name + '&from=customer'
      // console.log('url video call ws: ', url)
      var vm = this
      try {
        this.connection_call = new WebSocket(url)
      } catch(error) {
        // console.log('errore durante la connessione call ws, ristabilisco la connessione...')
        setTimeout(() => {
          vm.videoCallWS()
          vm.calling = false
          return
        }, 3000)
      }
      
      var ws = this.connection_call
      ws.onerror = function() {
        // console.log('Connection Error');
        this.calling = false
      }

      ws.onclose = function() {
        console.log('echo-protocol Client Closed CALL');
        vm.video_call = false
        vm.connection_call = null
        var loggedIn = vm.$store.state.user.isUserLoggedIn
        var path = vm.$route.path
        vm.notAnswered = true
        vm.calling = false
        ws.close()
        return
      }

      ws.onopen = function(event) {
        console.log('websocket CALL is connected ...');
        // console.log(event)
        // function checkStatus() {
        //   if (vm.connection && vm.$route.path === '/shop') {
        //     ws.send({ type: 'ask_presence' });
        //     setTimeout(checkStatus, 5000);
        //   } else {
        //     return
        //   }
        // }
        // checkStatus();
      }

      ws.onmessage = function(e) {
        // console.log("Received CALL: '" + e.data + "'")
        if(JSON.parse(e.data).type === 'call_accepted') {
          // console.log('CHIAMATA ACCETTATA')
          vm.calling = false
          vm.video_call = true
        } else if(JSON.parse(e.data).type === 'not_answered') {
          vm.notAnswered = true
          // setTimeout(() => {
          //   this.calling = false
          // }, 3000)
        } else if (JSON.parse(e.data).type === 'call_rejected') {
          // console.log('chiamata rifiutata')
          vm.calling = false
          vm.connection_call.close()
          vm.connection_call = null
        }
      }
    },
    async addRemoveFavoriteShop () {
      this.getting_result=true
      try {
        var user
        if (this.liked) {
          user = await ListingService.removeFavoriteShop(this.shop.id)
          console.log('deleted shop user: ', user)
        } else {
          user = await ListingService.addFavoriteShop({shop_id: this.shop.id})
          console.log('new user: ', user)
        }
        this.$store.dispatch('my_shops/setMyShops', user.data.myshops)
        // this.loading=false
      } catch(err) {
        console.log('error in addRemoveFavoriteShop(): ', err)
      } finally {
        this.getting_result=false
      }
    },
    openIframe(app) {
      // this.application_selected = app
      console.log(app)
      if (app.value.force_iframe)
        window.open(app.value.user_url, '_blank')
      else
        this.$router.push({ query: {  id: this.shop.id ,app: app.name }})
    },
    videoCall() {
      // mandare tramite socket un ack per dire che ci sono!!
      if(this.isFree === 'free'){
        // this.video_call = true
        this.calling = true
        this.videoCallWS()
      } else if(this.isFree === 'not-connected')
        alert('Il negoziante non è momentaneamente disponibile')
      else
        alert("Il negoziante è impeganto in un'altra chiamata")
    },
    closeCall() {
      this.connection_call.send(JSON.stringify({ type: 'call_terminated' }));
      this.notAnswered = true
      this.connection_call.close()
      this.calling=false
    },
    callClosed() {
      console.log('chiamata chiusa. Chiudo il canale')
      this.video_call = false
      this.connection_call.send(JSON.stringify({ type: 'call_terminated' }))
    }
  }
}
</script>

<style scoped>
  /* @import '../assets/css/style.css';
  @import '../assets/css/main-color.css'; */
  .sign-in_1 {
    color: #333 !important;
  }
  .to_dashboard_2 {
    color: rgb(249, 25, 66) !important;
  }
  .to_dashboard_2:hover {
    color: white !important;
  }
  .logo {
    width: 60px;
    height: auto;
  }
  .nopadding {
    padding-left: 0px !important;
  }
  .delivery {
    background-color: #f91942;
  }
  .calling {
    background-color: #3b5998;
  }
  @media only screen and (max-width: 600px) {
    .rating-overview {
      border: none;
      padding: 0px;
    }
    .nopadding {
      padding-bottom: 25px;
    }
    #titlebar {
      padding-top: 80px !important;
      padding-bottom: 20px !important;
    }
  }
  @media screen and (max-width: 450px) {
    .listing-tag {
      margin-top:18px !important;
    }
  }
  .app_third {
    cursor: pointer;
  }
  .video_call {
    background-color: #0033cc !important;
    cursor:pointer;
  }
  .video_disabled {
    background-color: #668cff !important;
    cursor: not-allowed;
  }
  .mt-custom {
    margin-top: 81px; 
  }
  @media (max-width: 1024px)  {
    .mt-custom {
      margin-top: 140px; 
    }
  }
  @media (max-width: 768px) {
    .rating-overview {
      min-height: 130px;
    }
  }
</style>
