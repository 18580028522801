import Api from '@/services/Api'
import axios from 'axios'
import fetch from 'node-fetch'
import config from '@/config'

export default {

    get_all( schema, options = {} ) {
        var url = `/model/${ schema }/`;
        var params = [];
        Object.keys( options ).map( key => {
            params.push( `${key }=${ options[ key ] }` );
        });
        if ( params.length > 0 ) { url += '?' + params.join('&'); }
        return Api().get( url );
    }

}

