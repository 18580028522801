import axios from 'axios'
import store from '@/store'
import config from '@/config'

export default () => {
  return axios.create({
    baseURL: config.backend_url,
    headers: {
      Authorization: `Bearer ${store.state.user.token}`,
      'Access-Control-Allow-Origin': '*'
    }
  })
}
