// la funzione principale è main
// bisogna passarle la variabile placeRsultData restituito da vue-google-autocomplete
function _find_attribute( address, tags, key = null ) {
  if ( typeof tags === 'string' ) { tags = [ tags ]; }
  var res = null;
  for ( var i = 0; i < address.address_components.length; i++ ) {
      var cmp = address.address_components[ i ];
      var found = true;
      if ( cmp.hasOwnProperty('types') ) {
          cmp.types.map( x => { if ( tags.includes( x ) === false ) { found = false; } } );
      }
      if ( found === true ) {
          if ( key === null ) {
              res = {
                  long_name : cmp.long_name,
                  short_name: cmp.short_name,
              };
          } else {
              res = cmp[ key ];
          }
          break;
      }
  }
  return res;
}
module.exports = {

  main(address) {

    var myesc = {};
    myesc.geodata   = address.geometry.location;

    return myesc;

  }

}
