<template>
  <div id="custom_overlay">
    <div class="meet_container col-xs-12 col-md-12 text-center">
      <div class="header_container col-xs-12 col-md-12">
        <div class="header_meet">
          <div class="col-xs-8 col-md-6 text-left" style="overflow: hidden;">
            <h3 class="calling_name">Chiamata con {{ shop_name }}</h3>
          </div>
          <div class="col-xs-4 col-md-6 text-right">
            <span class="meet_close" @click="closeMeet()">
              <!-- <i class="fa fa-close"></i> -->
            </span>
          </div>
        </div>
      </div>
      <div id="meet"></div>
    </div>
  </div>
</template>

<script>
import JitsiMeetExternalAPI from '@/utils/jitsi'
export default {
  name: 'VideoCall',
  props: ['shop_name', 'first_name', 'url'],
  data() {
    return {
      domain: '',
      options: {},
      api: null
    }
  },
  async mounted() {
    var vm = this
    this.$nextTick()
      .then(function () {
        let custom = document.createElement('script')
        custom.setAttribute('type', 'text/javascript')
        custom.setAttribute('src', 'scripts/custom.js?ver=1.5')
        // custom.async = true;
        document.body.appendChild(custom)

        vm.domain = "meet.jit.si";
        vm.options = {
          roomName: vm.url,
          width: '80%',
          // height: '720px',
          parentNode: document.querySelector('#meet'),
          userInfo: {
            displayName: vm.first_name
          }
        }
        vm.api = new JitsiMeetExternalAPI(vm.domain, vm.options);
        let conference_title = 'Chiamata - ' + vm.shop_name
        vm.api.executeCommand('subject', conference_title);
        vm.api.addEventListener('readyToClose', function (e) {
          console.log('CHIUSURA')
          vm.$eventHub.$emit('call_closed_client', true)
        });
      })
  },
  methods: {
    closeMeet() {
      // this.$eventHub.$emit('close_call', false)
      this.$eventHub.$emit('call_closed_client', false)
    }
  }
}
</script>

<style>
  #custom_overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
  }
  .meet_container {
    margin-top: 50px;
  }
  .header_meet {
    width: 82% !important;
    height: 80px;
    background-color: #f6f6f6;
    margin: auto;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
  }
  /* .meet_close {
    background-color: grey;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    float: right;
    color: #333;
    transition: .3s;
  }
  .meet_close:hover {
    background-color: #f91942;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    float: right;
    color: white;
    transition: .3s;
  } */
  .meet_close {
    position: absolute;
    right: 32px;
    top: 25px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .meet_close:hover {
    opacity: 1;
  }
  .meet_close:before, .meet_close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .meet_close:before {
    transform: rotate(45deg);
  }
  .meet_close:after {
    transform: rotate(-45deg);
  }
  @media (max-width: 992px) { 
    .calling_name {
      font-size: 14px;
    }
  }
  @media screen and (min-height: 900px) {
    #meet {
      height: 720px;
    }
  }
  @media screen and (max-height: 899px) {
    #meet {
      height: 600px;
    }
  }
  @media screen and (max-height: 759px) {
    #meet {
      height: 500px;
    }
  }
</style>