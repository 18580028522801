export const user_address = {
  namespaced: true,
  state: {
    address: null,
  },
  mutations: {
    setAddress (state, address) {
      state.address = address
    },
    setDefaultAddress (state, default_address) {
       for (let i = 0; i<state.address.length; i++) {
         if (state.address[i].label === 'default') {
           state.address[i] = default_address
           break
         }
       }
      //state.address.filter((el) => el.label === 'negozio')[0] = shop_address
    },
  },
  actions: {
    setAddress ({ commit }, address) {
      commit('setAddress', address)
    },
    setDefaultAddress ({ commit }, default_address) {
      commit('setDefaultAddress', default_address)
    },
  }
}