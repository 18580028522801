export const shop_images = {
  namespaced: true,
  state: {
    logo: null,
    gallery: []
  },
  mutations: {
    setLogo(state, logo) {
      state.logo = logo
    },
    setGallery(state, gallery) {
      state.gallery = gallery
    }
  },
  actions: {
    setLogo ({ commit }, logo) {
      commit('setLogo', logo)
    },
    setGallery ({ commit }, gallery) {
      commit('setGallery', gallery)
    }
  }
}