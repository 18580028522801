<template>
  <div>
    <!-- <h1>Informativa</h1> -->
    <h2>Your Address</h2>
    <vue-google-autocomplete
        ref="address"
        id="map"
        classname="form-control"
        placeholder="Please type your address"
        v-on:placechanged="getAddressData"
        country="it"
    >
    </vue-google-autocomplete>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
export default {
  data() {
    return {
      address: ''
    }
  },
  components: { VueGoogleAutocomplete },
  mounted() {
    this.$refs.address.focus();
  },
  methods: {
    /**
    * When the location found
    * @param {Object} addressData Data of the found location
    * @param {Object} placeResultData PlaceResult object
    * @param {String} id Input container ID
    */
    getAddressData: function (addressData, placeResultData, id) {
        this.address = addressData;
    }
  }
}
</script>

<style>

</style>